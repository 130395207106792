.mc-error {
    width: 100%;
    height: 100vh;
    @include flex-dimension(center, center, 0px);
    flex-direction: column;
    text-align: center;
    position: relative;
    padding: 50px 20px;
    z-index: 1;

    img { 
        width: 100%;
        max-width: 550px;
        margin-bottom: 50px; 
    }

    h3 {
        font-size: 22px;
        margin-bottom: 3px;
        text-transform: uppercase;
    }

    p { 
        font-size: 14px;
        margin-bottom: 25px; 
    }
}
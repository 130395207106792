* {
    margin: 0px;
    padding: 0px;
    outline: 0px;
    box-sizing: border-box;
}

img {
    vertical-align: middle;
}

a {
    text-decoration: none;
    display: inline-block;
    color: var(--textColor);

    &:hover { 
        text-decoration: none; 
        color: inherit;
    }
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

sup, 
sub {
    top: inherit;
    bottom: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
    color: var(--headingColor);
}

p {
    margin: 0px;
}

html,
body {
    scroll-behavior: smooth;
    color: var(--textColor);
    background-color: var(--bodyBG);
}

html[dir="rtl"] {
    direction: rtl;
}

html[dir="ltr"] {
    direction: ltr;
}

input,
select,
button,
textarea {
    border: none;
    outline: none;
    background: none;
    color: var(--textColor);

    &:focus { outline: none; }
    &::placeholder { color: var(--placeholderColor); }
}

button {
    cursor: pointer;
    line-height: 0px;
    color: var(--textColor);
}
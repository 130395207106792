.mc-product-view-gallery {
    display: grid;
    padding: 10px;
    grid-gap: 20px;
    grid-template-columns: 12;
    grid-template-rows: auto;

    img {
        width: 100%;
        border-radius: 8px;
        border: 2px dashed var(--deepBorder);
        filter: drop-shadow(0px 10px 15px rgba(var(--blackRGB), 5%));

        &:nth-child(1) { grid-column: 1 / 13; }
        &:nth-child(2) { grid-column: 1 / 4; }
        &:nth-child(3) {  grid-column: 4 / 7; }
        &:nth-child(4) { grid-column: 7 / 10; }
        &:nth-child(5) { grid-column: 10 / 13; }
    }
}

.mc-product-view-info-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}

.mc-product-view-info-title {
    @include text-font(22px, 500);
    margin-bottom: 15px;
    line-height: 30px;
}

.mc-product-view-meta {
    @include flex-dimension(flex-start, flex-start, 0px);

    i { 
        font-size: 20px;
    }

    h5 {
        width: 90px;
        font-size: 15px;
        text-transform: capitalize;
    }

    span { 
        line-height: 16px;
    }

    p {
        font-size: 15px;
        line-height: 18px;
        text-transform: capitalize;

        del {
            color: $red-600;
        }
    }

    ul {
        @include flex-dimension(center, flex-start, 8px);
        flex-wrap: wrap;
        
        li {
            @include text-font(11px, 500);
            text-transform: uppercase;
            border-radius: 4px;
            padding: 4px 8px;
            background-color: var(--lightGray);
        }
    }
}

[dir="ltr"] {
    .mc-product-view-meta {
        i { margin-right: 12px }
        span { margin-right: 20px }
        p del { margin-left: 3px }
    }
}

[dir="rtl"] {
    .mc-product-view-meta {
        i { margin-left: 12px }
        span { margin-left: 20px }
        p del { margin-right: 3px }
    }
}

.mc-product-view-descrip {
    padding: 0px 10px 10px;

    p { 
        font-size: 15px;
        line-height: 30px; 
    }
}

.modal {
    background-color: rgba(var(--blackRGB), 50%);
}

.modal-close {
    position: absolute;
    top: -12px;

    i {
        @include content-center(35px, 35px, 50%);
        font-size: 20px;
        color: var(--textColor);
        background-color: var(--thinGray);
        @include transition-linear;

        &:hover {
            color: var(--lightColor);
            background: $red-600;
        }
    }
}

[dir="ltr"] .modal-close { 
    right: -12px; 
}

[dir="rtl"] .modal-close { 
    left: -12px; 
}

.modal-dialog {
    margin: 100px auto;
    max-width: fit-content;
}

.modal-content {
    border-radius: 8px;
    background-color: var(--lightBG);
}

.modal-header {
    padding: 0px;
    border: none;
    margin-bottom: 30px;
}

.modal-title {
    @include text-font(20px, 600);
    text-transform: capitalize;
    color: var(--headingColor);
}

.modal-footer {
    padding: 0px;
    border: none;
    margin-top: 35px;
    justify-content: center;
    gap: 15px;
    
    >* { margin: 0px; }
}

// user modal
.mc-user-modal {
    width: 450px;
    padding: 0px 40px 40px;

    img {
        @include content-center(100px, 0, 50%);
        border: 12px solid var(--lightColor);
        margin: -45px auto 0px;
        display: block;
    }

    h4 {
        font-weight: 700;
        text-align: center;
        text-transform: capitalize;
    }

    p { 
        font-size: 15px; 
        margin-bottom: 25px;
        text-align: center;
    }
}

// alert modal
.mc-alert-modal {
    width: 450px;
    padding: 0px 35px 35px;
    text-align: center;

    i {
        font-size: 45px;
        margin-top: -35px;
        @include content-center(80px, 80px, 50%);
        text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.120);
        color: $red-700;
        background-color: var(--lightBG);
    }

    h3 {
        @include text-font(24px, 800);
        text-transform: capitalize;
        margin-bottom: 5px;
    }

    p {
        font-size: 18px;
    }
}
.mc-file-upload {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    border-radius: 8px;
    color: var(--textColor);
    background-color: var(--lightGray);
    @include transition-linear;

    &:hover {
        color: var(--lightColor);
        background-color: var(--primaryHEX);
    }

    &.button {
        label {
            cursor: pointer;
            padding: 10px 22px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            @include text-font(13px, 500);
            @include flex-dimension(center, center, 8px);

            i {
                font-size: 20px;
            }
        }
    }

    input {
        width: 100%;
        height: 100%;
        visibility: hidden;
        @include position-top-left(absolute, 0px, 0px, 1);
    }
}
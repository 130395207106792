.thin-scrolling {
    overflow-y: auto;
    @include transition-linear;

    &:hover { 
        &::-webkit-scrollbar-thumb { 
            background-color: var(--primaryHEX); 
        }
    }

    &::-webkit-scrollbar { 
        width: 3px;
    }
    
    &::-webkit-scrollbar-thumb { 
        border-radius: 15px;
        background-color: transparent; 
    }
}